export const getCompany = (state) => {
  if (state.company.company) return state.company.company
  return false
}

export const getCompanyProfile = (state) => {
  if (state?.company?.company?.CompanyProfile) return state.company.company.CompanyProfile
  return false
}

export const getCompanyMembers = (state) => {
  if (state?.company?.company?.CompanyMembers) return state.company.company.CompanyMembers
  return false
}

export const getCompanyMessage = (state) => {
  if (state.company) return { success: state.company.updateMessage, error: state.company.errorMessage }
  return false
}
export const getEditRoleMessage = (state) => {
  if (state.company)
    return {
      success: state.company.editMessage || state.company.addedMessage,
      error: state.company.errorMessage
    }
  return false
}

export const getCompanyCountries = (state) => {
  if (state.company.countries) return state.company.countries
  return false
}

export const getCompanyIndustries = (state) => {
  if (state.company.industries) return state.company.industries
  return false
}
export const getCompanyLoading = (state) => {
  if (state.company.updateCompanyLoading) return state.company.updateCompanyLoading
  return false
}

export const getCompanyMedia = (state) => {
  if (state.company.media) return state.company.media
  return false
}
export const getMeiaAddedMessage = (state) => {
  if (state.company)
    return {
      success: state.company.addedMessage || false,
      updated: state.company.updatedMessage || false,
      delete: state.company.deletedMessage  || false,
      duplicate: state.company.duplicatedMessage  || false,
      error: state.company.errorMessage  || false,
      showLoading: state.company.showLoading
    }
  return false
}
export const getCompanyCurrentPosition = (state) => {
  if (state.company.currentPosition) return state.company.currentPosition
  return false
}

export const getCompanyCurrentPositionQuestions = (state) => {
  if (state.company.currentPosition?.Questions) return state.company.currentPosition?.Questions
  return false
}

export const getCompanyPositions = (state) => {
  if (state.company.position) return state.company.position
  return false
}

export const getCompanySubscription = (state) => {
  if (state.company.subscription)
    return {
      ...state.company.subscription,
      limit: JSON.parse(state.company.subscription.limit)
      // name: state.company.subscription.name,
    }
  return false
}
export const getCompanyCandidates = (state) => {
  if (state.company.candidates) return state.company.candidates
  return false
}
export const getCompanyCandidateFilterDataSelector = (state) => {
  if (state.company.candidatesFilterData) return state.company.candidatesFilterData
  return false
}
export const getCompanyEvaluations = (state) => {
  if (state.company.evaluations) return state.company.evaluations
  return false
}
export const getDataForTagsModal = (state) => {
  if (state.company.candidatesFilterData) return state.company.candidatesFilterData
  return false
}
export const getDataForMembersModal = (state) => {
  if (state?.company?.company?.CompanyMembers) return state.company.company.CompanyMembers
  return false
}
export const getCurrentEvaluationDetailSelector = (state) => {
  if (state?.company?.currentEvaluation) return state.company.currentEvaluation
  return false
}
export const getCompanyDashboardSelector = (state) => {
  if (state?.company?.dashboard) return state.company.dashboard
  return false
}
export const getCompanyDashboardPositionsSelector = (state) => {
  if (state?.company?.dashboardPositions) return state.company.dashboardPositions
  return false
}
export const getCompanyDashboardLoadingSelector = (state) => {
  if (state?.company?.dashboardLoading) return state.company.dashboardLoading
  return false
}
export const getCompanyDashboardEvaluationSelector = (state) => {
  if (state?.company?.dashboardEvaluationGraph) return state.company.dashboardEvaluationGraph
  return false
}
export const getCompanyDashboardEvaluationLoadingSelector = (state) => {
  if (state?.company?.dashboardEvaluationGraphLoading) return state.company.dashboardEvaluationGraphLoading
  return false
}
export const getCompanyDashboardInterviewSelector = (state) => {
  if (state?.company?.dashboardInterviewGraph) return state.company.dashboardInterviewGraph
  return false
}
export const getCompanyDashboardInterviewLoadingSelector = (state) => {
  if (state?.company?.dashboardInterviewGraphLoading) return state.company.dashboardInterviewGraphLoading
  return false
}

export const getCompanyPaymentsSelector = (state) => {
  if (state?.company?.payments) return state.company.payments
  return false
}

export const getCompanySubscriptionPlansSelector = (state) => {
  if (state?.company?.subscriptionPlans) return state.company.subscriptionPlans
  return false
}

export const getDashboardPositionSelector = (state) => {
  if (state?.company?.dashboardPositions) return state.company.dashboardPositions.data
  return false
}
